import server from '../request';

export default {
  getUserList ({
    status,
    userSts,
    pageIndex,
    pageSize,
    realName,
  }) {
    return server.post('/xiaozi-rbac/api/account/queryPageList', {
      userSts,
      accountSts: status,
      pageNum: pageIndex,
      pageSize: pageSize,
      realName: realName,
    });
  },

  // 获取七牛上传token
  getQiniuToken (key = 0) {
    let qiniuUrl = key === 1 ? '/xiaozi-saas/wireless/system/getYxbUploadToken' : '/xiaozi-base/file/getUploadToken';
    return server.post(qiniuUrl);
  },
};
