<template>
<div class="layout">
    <LeftMenu v-if="!isJumped" :isCollapse="isCollapse"></LeftMenu>
    <el-container v-if="isLoaded" class="container">
        <Header v-if="!isJumped" @collapse="val=>isCollapse=val"></Header>
        <el-main>
            <div :class="['container__content', isJumped ? 'hideRouterTab' : '']">
              <router-tab :contextmenu="contextmenu" restore append="next" restore-watch :max-alive="16">
                <template #default="tab">
                  <span class="router-tab__item-title" :title="getTabTitle(tab)">
                    {{ getTabTitle(tab) }}
                  </span>
                  <i v-if="tab.closable" class="router-tab__item-close" @click.prevent="tab.close" />
                </template>
              </router-tab>
            </div>
        </el-main>
    </el-container>
</div>
</template>
<script>
import LeftMenu from './leftMenu.vue';
import Header from './header.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'layout',
  components: {
    LeftMenu,
    Header,
  },
  data () {
    return {
      isCollapse: false,
    };
  },
  computed: {
    ...mapGetters([
      'menuTree',
      'menuUrlMap',
      'isJumped',
    ]),
    isLoaded () {
      return this.menuTree && this.menuTree[0];
    },
    contextmenu () {
      return [
        {
          id: 'refresh',
          icon: 'el-icon-refresh',
        },
        {
          id: 'close',
          icon: 'el-icon-close',
        },
        {
          id: 'closeOthers',
          icon: 'el-icon-circle-close',
        },
        {
          id: 'closeRights',
          icon: 'el-icon-right',
        },
      ];
    },
  },
  methods: {
    getTabTitle (tab) {
      const menu = this.menuUrlMap[tab.id];
      return menu[0].menuName;
    },
  },
};
</script>

<style lang='scss' scoped>
    .layout {
        height: 100%;
        display: flex;

        >.container{
            flex: 1;
            flex-direction: column;
            overflow: hidden;

            /deep/>.el-main {
              padding: 0 12px;
              background-color: rgb(240,242,245);
              display: flex;
              flex-direction: column;

              .container__content{
                flex: 1;
                height: 100%;

                &.hideRouterTab {
                  .router-tab__header {
                    display: none;
                  }
                }

                >.router-tab {
                  height: 100%;
                  >.router-tab__header {
                    .router-tab__nav {
                      align-items: center;

                      .router-tab__item {
                        background: white;
                        border-radius: 4px;
                        margin-right: 6px;
                        padding: 0 16px;
                        height: 32px;
                        border: 1px solid #eaecef !important;

                        &.is-active,
                        &:hover {
                          color: $primary-color;
                        }

                        .router-tab__item-close {
                          margin-left: 4px;
                          width: 13px;
                        }
                      }
                    }

                    .router-tab__scrollbar-thumb:hover,
                    .router-tab__scrollbar.is-dragging .router-tab__scrollbar-thum {
                      background-color: $primary-color;
                    }
                  }

                  > .router-tab__container {
                    background: transparent;
                  }

                  .router-tab__contextmenu-item:active,
                  .router-tab__contextmenu-item:hover {
                    color: $primary-color;
                  }
                }
              }

              .container_dashboard{
                margin: 0 -12px;
              }
            }
        }
    }
    .moveR-enter-active,  .moveR-leave-active {
        transition: all 0.3s linear;
        transform: translateX(0);
    }
    .moveR-enter,  .moveR-leave {
        transform: translateX(100%);
    }
   .moveR-leave-to{
     transform: translateX(100%);
   }
</style>
