export default {
  currentUserInfo (state) {
    return state.currentUserInfo;
  },

  isLogin (state) {
    return state.currentUserInfo && state.currentUserInfo.loginUserId;
  },

  isJumped (state) {
    return state.isJumped;
  },
};
