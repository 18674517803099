import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/element.js';
import './plugins/vxe-table';
import './plugins/router-tab';
import Util from './utils/util';
import './filters';
import directives from './directives';

import './assets/css/common.scss';
import './assets/css/custom.scss';

import '@/assets/iconfont/iconfont.css';
import '@/assets/iconfont/iconfont.js';

Vue.config.productionTip = false;
Vue.use(Util);
Vue.use(directives);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
