<template>
    <div class="bottom_panel">
        <div class="bottom_panel--placeholder"></div>
        <div :class="className">
            <slot></slot>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'bottom-panel',
  props: {
    direction: {
      type: String,
      default: () => {
        return 'right';
      },
    },
  },
  computed: {
    ...mapGetters([
      'isJumped',
    ]),
    className () {
      const ret = [['bottom_panel--content']];

      if (this.direction && this.direction.toUpperCase() === 'RIGHT') {
        ret.push('flex-right');
      } else if (this.direction && this.direction.toUpperCase() === 'MIDDLE') {
        ret.push('flex-middle');
      }

      if (this.isJumped) {
        ret.push('xzyl__width--full');
      }

      return ret;
    },
  },
  data () {
    return {};
  },
  created () {
    if (this.direction && this.direction.toUpperCase() === 'RIGHT') {
      this.className.push('flex-right');
    } else if (this.direction && this.direction.toUpperCase() === 'MIDDLE') {
      this.className.push('flex-middle');
    }
  },
};
</script>
<style lang="scss" scoped>
.bottom_panel{
    .bottom_panel--placeholder{
      width: 100%;
      height: 60px;;
    }
    .bottom_panel--content{
      position: fixed;
      bottom: 0;
      right: 0;
      height: 60px;
      width: calc(100% - 220px);
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      box-sizing: border-box;
      align-items: center;
      box-shadow: rgb(245, 245, 245) -10px -10px 35px;
      z-index: 100;
      background: white;

      &.flex-right{
          text-align: right;
      }

      &.flex-middle{
          text-align: center;
      }

      &.xzyl__width--full {
        width: 100%;
      }
    }
}
</style>
