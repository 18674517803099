import server from '../request';

export default {
  login ({ userName, password, byType, captchaKey, captcha }) {
    return server.post('/xiaozi-rbac/login', {
      userName,
      password,
      byType,
      captchaKey,
      captcha,
    });
  },
  logout () {
    return server.post('/xiaozi-rbac/loginOut', {});
  },
  // 获取图形验证码
  getValidImg () {
    return server.post('/xiaozi-rbac/getCaptcha', {});
  },
  // 获取菜单列表
  getTreeMenuList () {
    return server.post('/xiaozi-rbac/api/menu/treeMenuList');
  },

  // 获取菜单列表
  getTreeMenuListWithAuth () {
    return server.post('/xiaozi-rbac/api/menu/treeMenuListById');
  },
  // 文字验证码
  getCaptcha (data) {
    return server.post('/xiaozi-saas/captcha/get', data);
  },
  checkCaptcha (data) {
    return server.post('/xiaozi-saas/captcha/check', data);
  },
  // 修改密码
  updateAccountPassword (params) {
    return server.post('/xiaozi-saas/wireless/tenant/updatePasswordByPhone', params);
  },
  // 发送手机验证码
  sendValidCode ({ captchaVO, phone, captchaType }) {
    return server.post('/xiaozi-saas/wireless/tenant/sendCaptcha', {captchaVO, phone, captchaType});
  },
};
