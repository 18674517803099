import ContentTitle from '@/components/common/layout/contentTitle.vue';
import ContentBlock from '@/components/common/layout/contentBlock';
import BottomPanel from '@/components/common/bottomPanel/index.vue';
import QueryForm from '@/components/common/queryForm.vue';
import TableWithPagination from '@/components/common/TableWithPagination';
import Upload from '@/components/common/upload';

// 本地localstorage缓存
let local = {
  set: function (key, value) {
    if (typeof (value) === 'object') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  },
  get: function (key) {
    return localStorage.getItem(key) || '';
  },
  remove: function (key) {
    localStorage.removeItem(key);
  },
  clear: function () {
    localStorage.clear();
  },
};
// 本地session缓存
let session = {
  set: function (key, value) {
    if (typeof (value) === 'object') {
      value = JSON.stringify(value);
    }
    sessionStorage.setItem(key, value);
  },
  get: function (key) {
    return sessionStorage.getItem(key) || '';
  },
  remove: function (key) {
    sessionStorage.removeItem(key);
  },
  clear: function () {
    sessionStorage.clear();
  },
};

export default {
  install: function (Vue) {
    Vue.prototype.$local = local;
    Vue.prototype.$session = session;
    Vue.component('ContentTitle', ContentTitle);
    Vue.component('ContentBlock', ContentBlock);
    Vue.component('BottomPanel', BottomPanel);
    Vue.component('QueryForm', QueryForm);
    Vue.component('TableWithPagination', TableWithPagination);
    Vue.component('Upload', Upload);
  },
};
