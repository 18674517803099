import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../components/layout';
import { RouterTabRoutes } from 'vue-router-tab';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import store from '../store';

NProgress.configure({showSpinner: false});

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
// TODO:
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(() => {});
};

// 重写了VueRouter的go和back方法，在router返回时关闭当前页签
VueRouter.prototype.go = VueRouter.prototype.back = function go () {
  let items = Vue.prototype.$tabs?.items;
  let activeItemId = Vue.prototype.$tabs?.activeTabId;
  let activeItemIndex = items.findIndex((t) => t.id === activeItemId);
  if (activeItemIndex > 0) {
    Vue.prototype.$tabs.close({ id: activeItemId, to: items[activeItemIndex - 1].to });
  } else {
    Vue.prototype.$tabs.close();
  }
};

// 重新replace方法，使之匹配vue-routeTab
const originReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace () {
  let activeTabId = Vue.prototype.$tabs?.activeTabId;
  originReplace.apply(this, arguments).then(() => {
    activeTabId && Vue.prototype.$tabs.close({ id: activeTabId });
  });
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: { name: 'TENANT_INDEX' },
    children: [
      ...RouterTabRoutes,
      {
        path: 'saas/tenant/purchase/index',
        name: 'TENANT_PURCHASE_INDEX',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Tenant/purchase'),
      },
      {
        path: 'saas/tenant/purchase/detail',
        name: 'TENANT_PURCHASE_DETAIL',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Tenant/purchase/detail'),
        props: (route) => ({
          id: route.query.id,
        }),
      },
      {
        path: 'saas/tenant/index',
        name: 'TENANT_INDEX',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Tenant'),
      },
      {
        path: 'saas/tenant/add',
        name: 'TENANT_ADD',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Tenant/edit'),
      },
      {
        path: 'saas/tenant/detail',
        name: 'TENANT_DETAIL',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Tenant/detail'),
        props: (route) => ({
          id: route.query.id,
        }),
      },
      {
        path: 'saas/tenant/approve',
        name: 'TENANT_APPROVE',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Tenant/edit'),
        props: (route) => ({
          id: route.query.id,
        }),
      },
      {
        path: 'saas/product/index',
        name: 'PRODUCT_INDEX',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Product'),
      },
      {
        path: 'saas/product/setting/function',
        name: 'PRODUCT_RESOURCE',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Product/resource'),
        props: (route) => ({
          id: route.query.id,
        }),
      },
      {
        path: 'saas/product/setting/datascope',
        name: 'PRODUCT_DATASCOPE',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Product/datascope'),
        props: (route) => ({
          id: route.query.id,
        }),
      },
      {
        path: 'saas/product/setting/base',
        name: 'PRODUCT_ORG_ROLE',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Product/orgRole'),
        props: (route) => ({
          id: route.query.id,
        }),
      },
      {
        path: 'saas/feedback/index',
        name: 'FEEDBACK_INDEX',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Feedback/index'),
      },
      {
        path: 'saas/feedback/detail',
        name: 'FEEDBACK_DETAIL',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Feedback/detail'),
        props: (route) => ({
          id: route.query.id,
          readonly: true,
        }),
      },
      {
        path: 'saas/feedback/process',
        name: 'FEEDBACK_PROCESS',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Feedback/detail'),
        props: (route) => ({
          id: route.query.id,
          readonly: false,
        }),
      },
      {
        path: 'saas/promotion/list',
        name: 'Promotion',
        component: () => import(/* webpackChunkName: 'about' */ '@/views/Promotion/index'),
      },
      {
        path: 'saas/promotion/detail',
        name: 'PromotionDetail',
        component: () => import(/* webpackChunkName: 'about' */ '@/views/Promotion/detail'),
      },
      {
        path: 'channel/order/list',
        name: 'CHANNERL_ORDER_LIST',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Order'),
      },
      {
        path: 'channel/order/invoicing',
        name: 'CHANNERL_ORDER_Invoicing',
        component: () => import(/* webpackChunkName: 'about' */ '../views/Invoicing'),
      },
      {
        path: 'saas/interfaceConfiguration',
        name: 'InterfaceConfiguration',
        component: () => import(/* webpackChunkName: 'about' */ '@/views/SystemSetting/interfaceConfiguration'),
      },
      {
        path: 'saas/report/repairman-hospital',
        name: 'REPORT_REPAIRMAN_HOSPITAL',
        component: () => import(/* webpackChunkName: 'report' */ '../views/Report/repairman'),
      },
      {
        path: 'saas/report/repairman-hospital-detail',
        name: 'REPORT_REPAIRMAN_HOSPITAL_DETAIL',
        component: () => import(/* webpackChunkName: 'report' */ '../views/Report/repairman/detail'),
        props: (route) => ({
          id: route.query.id,
        }),
      },
      {
        path: 'saas/report/business',
        name: 'SASS_REPORT_BUSINESS',
        component: () => import(/* webpackChunkName: 'report' */ '../views/Report/business'),
      },
      {
        path: 'saas/report/order/monitoring',
        name: 'SASS_REPORT_ORDER',
        component: () => import(/* webpackChunkName: 'report' */ '../views/Report/order'),
      },
      {
        path: 'saas/report/order/detail',
        name: 'SASS_REPORT_ORDER_DETAIL',
        component: () => import(/* webpackChunkName: 'report' */ '../views/Report/order/detail'),
        props: (route) => ({
          ...route.query,
        }),
      },
      {
        path: 'saas/gxtgl/cooperationSubscription',
        name: 'SASS_GXTGL_COOPERATIONSUBSCRIPTION',
        component: () => import(/* webpackChunkName: 'report' */ '../views/Gxtgl/cooperationSubscription'),
        props: (route) => ({
          ...route.query,
        }),
      },
      {
        path: 'saas/gxtgl/enterprise',
        name: 'SASS_GXTGL_ENTERPRISE',
        component: () => import(/* webpackChunkName: 'report' */ '../views/Gxtgl/enterprise'),
        props: (route) => ({
          ...route.query,
        }),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: 'about' */ '../views/Login.vue'),
  },
];

const router = new VueRouter({
  routes,
});

const xiaoziyixueJumpHere = (to) => {
  if (to.query.from === 'xzyx' && to.query.auth) {
    localStorage.setItem('token', to.query.auth);

    store.commit('UPDATE_ISJUMPED', true);

    if (!store.getters.menuTree.length) {
      store.dispatch('getMenuInfo');
    }

    if (JSON.stringify(store.getters.currentUserInfo) === '{}') {
      store.commit('UPDATE_USERINFO', JSON.parse(to.query.userInfo));
    }
  }
};

router.beforeEach((to, from, next) => {
  xiaoziyixueJumpHere(to);

  let isLogin = !!localStorage.getItem('token');
  if (to.name === 'Login') {
    if (isLogin) {
      next({
        name: 'Home',
      });
      return;
    } else {
      NProgress.start();
      next();
      return;
    }
  }
  if (!isLogin && to.query?.from !== 'xzyx') {
    next({
      name: 'Login',
    });
    return;
  }
  NProgress.start();
  next();
});
router.afterEach(() => {
  NProgress.done();
});

export default router;
