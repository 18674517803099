<template>
  <div class="header">
    <div class="menu">
      <div class="menu_action">
        <i
          :class="`${
            isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'
          } cursor-pointer`"
          @click="handleCollapse"
        ></i>
      </div>
    </div>
    <div>
      <el-dropdown
        class="user_Info"
        @visible-change="val => (userMenuVisible = val)"
        size="default"
      >
        <div class="cursor-pointer">
          <div>
            <span class="userInfo_name">
              {{
              this.$store.getters.currentUserInfo &&
              this.$store.getters.currentUserInfo.realName
              }}
            </span>
          </div>
          <i
            :class="[
              userMenuVisible ? 'el-icon-arrow-up' : 'el-icon-arrow-down',
              'el-icon--right'
            ]"
          ></i>
        </div>
        <el-dropdown-menu slot="dropdown" class="user_info_drop_menu">
          <el-dropdown-item @click.native="updatePwdDialogVisible = true">修改密码</el-dropdown-item>
          <el-dropdown-item @click.native="handleLoginOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog title="修改密码" width="460px" :visible.sync="updatePwdDialogVisible" :close-on-click-modal="false">
      <el-form ref="updatePwdForm" :model="updatePwdForm" :rules="updatePwdFormRules" label-width="120px">
        <el-form-item prop="phone" label="手机号">
          <div>{{ updatePwdForm.phone }}</div>
        </el-form-item>
        <el-form-item prop="code" label="验证码">
          <el-input placeholder="请输入短信验证码" type="text" autocomplete="off" v-model="updatePwdForm.code">
            <span
              slot="append"
              :class="['cursor-pointer', 'send_btn', loginMsgTimerTick > 0 && 'disabled_send']"
              @click="handleCheckCode"
            >
              {{ loginMsgTip }}
            </span>
          </el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd">
          <el-input
            type="password"
            v-model.trim="updatePwdForm.newPwd"
            placeholder="请输入新密码"
            show-password
            autocomplete="new-password"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="repeatPwd">
          <el-input
            type="password"
            v-model.trim="updatePwdForm.repeatPwd"
            placeholder="请输入确认密码"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="updatePwdDialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="handleUpdatePwd">保存</el-button>
      </span>
    </el-dialog>
    <verifition
      mode="pop"
      captchaType="clickWord"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
      @success="handleCheckCodeSuccess"
    />
  </div>
</template>

<script>
import Verifition from '@/components/verifition';
import AccountApi from '@/api/login';
export default {
  name: 'Header',
  components: {
    Verifition,
  },
  data: function () {
    const userInfo = JSON.parse(localStorage.userInfo);
    return {
      userInfo,
      userMenuVisible: false,
      isCollapse: false,
      updatePwdDialogVisible: false,
      updatePwdForm: {
        newPwd: '',
        repeatPwd: '',
        code: '',
        id: '',
        phone: userInfo.phoneNo,
      },
      loginMsgTimerTick: 0,
      loginMsgTip: '发送短信验证码',
      updatePwdFormRules: {
        code: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
        newPwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { max: 11, min: 6, message: '请输入6-11位字符', trigger: 'blur' },
        ],
        repeatPwd: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          { max: 11, min: 6, message: '请输入6-11位字符', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (this.updatePwdForm.newPwd !== value) {
                callback(new Error('两次密码输入不一致'));
              }
              callback();
            },
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    updatePwdDialogVisible () {
      if (!this.updatePwdDialogVisible) {
        this.$refs.updatePwdForm.resetFields();
        this.$refs.updatePwdForm.clearValidate();
      }
    },
  },
  methods: {
    async handleCheckCode () {
      if (this.loginMsgTimerTick > 0) return;
      this.$refs.verify.show();
    },
    handleCheckCodeSuccess (captchaVO) {
      let phone = '';
      phone = this.updatePwdForm.phone;
      AccountApi.sendValidCode({ captchaVO, phone, captchaType: 'UPDATEPASSWORD' }).then(() => {
        if (!this.loginMsgTimerId) {
          this.loginMsgTimerTick = 120;
          this.loginMsgTimerId = setInterval(() => {
            this.loginMsgTimerTick--;
            this.loginMsgTip = `重新获取(${this.loginMsgTimerTick})`;
            if (this.loginMsgTimerTick === 0) {
              clearInterval(this.loginMsgTimerId);
              this.loginMsgTimerId = '';
              this.loginMsgTip = '获取验证码';
            }
          }, 1000);
        }
      });
    },
    handleUpdatePwd () {
      this.$refs.updatePwdForm.validate(valid => {
        if (valid) {
          AccountApi.updateAccountPassword({
            phone: this.userInfo.phoneNo,
            onePassword: this.updatePwdForm.newPwd,
            twoPassword: this.updatePwdForm.repeatPwd,
            code: this.updatePwdForm.code,
          }).then(() => {
            this.$message.success('密码修改成功，请重新登录');
            this.updatePwdDialogVisible = false;
            this.reLogin();
          });
        }
      });
    },
    reLogin () {
      this.$local.clear();
      this.$session.clear();
      this.$router.push({
        name: 'Login',
      });
    },
    handleLoginOut () {
      // 退出操作
      this.$confirm('确认是否要退出？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        // 确认操作
        AccountApi.logout().then(() => {
          this.reLogin();
        });
      });
    },
    handleCollapse () {
      this.isCollapse = !this.isCollapse;
      this.$emit('collapse', this.isCollapse);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 47px;
  display: flex;
  background: white;
  color: #242832;
  align-items: center;
  padding: 0 30px;
  font-size: 14px;
  border-bottom: 1px solid rgba(113, 121, 141, 0.08);
  box-shadow: 0px 2px 4px 0px rgba(36, 40, 50, 0.08);

  .menu {
    flex: 1;

    .el-menu {
      border: none;

      .el-menu-item {
        height: 46px;
        line-height: 46px;
      }
    }
  }
  .logo {
    width: 280px;
    font-size: 16px;
    font-size: 0px;
    vertical-align: middle;
    color: #242832;
  }

  .user_Info {
    color: #242832;
    height: 100%;
    display: flex;
    align-items: center;
    & > div {
      display: flex;
      align-items: center;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 8px;
        .userInfo_warehouse {
          font-size: 9px;
          color: gray;
        }
      }
    }
  }
}

.user_info_drop_menu {
  min-width: 120px;
  /deep/ .el-dropdown-menu__item {
    text-align: center;
  }
}
.infoMessage {
  font-size: 12px;
  text-align: right;
  transform: scale(0.9);
}
.send_btn {
  color: #409EFF;
}
.disabled_send {
  cursor: no-drop;
}
</style>
