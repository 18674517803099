<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
export default {
  name: 'App',
  methods: {
    ...mapActions({
      getMenuInfo: 'getMenuInfo',
    }),
    ...mapMutations({
      updateUserInfo: 'UPDATE_USERINFO',
    }),
  },
  computed: {
    ...mapGetters([
      'menuTree',
    ]),
  },
  async created () {
    let userInfo = this.$local.get('userInfo');
    if (userInfo) {
      this.updateUserInfo(JSON.parse(userInfo));
      await this.getMenuInfo();
      if (this.$route.name === 'Home') {
        this.$router.push(this.menuTree[0]?.url);
      }
    }
  },
};
</script>
<style>
html,body {
  width: 100%;
  height: 100%;
  min-width: 1366px;
}

* {
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
