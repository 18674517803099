import Vue from 'vue';
import XEUtils from 'xe-utils';
import zhCN from 'vxe-table/lib/locale/lang/zh-CN';
import { VXETable, Header, Footer, Column, Toolbar, Edit, Grid, Pager, Table, Button, Select, Option, Tooltip, Filter } from 'vxe-table';
import 'vxe-table/lib/style.css';
VXETable.setup({
  zIndex: 8888,
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args),
});
Vue.use(VXETable)
  .use(Header)
  .use(Footer)
  .use(Column)
  .use(Toolbar)
  .use(Edit)
  .use(Grid)
  .use(Pager)
  .use(Filter)
  .use(Table)
  .use(Button)
  .use(Select)
  .use(Option)
  .use(Tooltip);
