<template>
  <div :class="className">
    <el-upload
        v-if="type === 'list'"
        :limit="limit"
        :accept="accept"
        multiple
        :action="uploadDomain"
        list-type="picture-card"
        :data="qiniuData"
        :before-upload="handleBeforeUpload"
        :on-exceed="handleLimitExceed"
        :on-success="uploadSuccess"
        :file-list="fileList">
        <i slot="default" class="el-icon-plus" ></i>
        <div slot="file" slot-scope="{file}">
            <img
            class="el-upload-list__item-thumbnail"
            :src="getDefaultCover(file)" alt=""
            >
            <span class="el-upload-list__item-actions">
            <span
                class="el-upload-list__item-preview"
                @click="handleImagePreview(file)"
            >
                <i class="el-icon-zoom-in"></i>
            </span>
            <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleImageRemove(file)"
            >
                <i class="el-icon-delete"></i>
            </span>
            </span>
        </div>
    </el-upload>
    <el-upload v-else
        :limit="limit"
        :accept="accept"
        :action="uploadDomain"
        :show-file-list="showFileList"
        :data="qiniuData"
        multiple
        :before-upload="handleBeforeUpload"
        :on-exceed="handleLimitExceed"
        :on-success="uploadSuccess">
        <slot name='trigger'>
          <el-button size="mini" type="primary">选取文件</el-button>
        </slot>
    </el-upload>
    <slot  v-if='isDescription'>PDF文件请点击查看按钮预览</slot>
    <div v-if="tip && !disabled" class="el-upload__tip" slot="tip" ><b>注</b>：只能上传<span style="color:red">{{acceptDesc}}</span>类型的文件，且文件大小不可超过<span style="color:red">{{maxSize}}MB</span>。</div>
  </div>
</template>
<script>
import _ from 'lodash';
import AccountApi from '@/api/account';
export default {
  name: 'upload',
  props: {
    // 是否只读
    disabled: {
      type: Boolean,
    },
    // 展示的图片列表
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tip: {
      type: Boolean,
      default: true,
    },
    isDescription: {
      type: Boolean,
      default: false,
    },
    // 上传类型 list：卡片列表  single:单独上传按钮
    type: {
      type: String,
      default: 'list',
    },
    label: {
      type: String,
      default: () => {
        return '附件';
      },
    },
    // 显示附件列表
    showFileList: {
      type: Boolean,
      default: true,
    },
    // 组件大小，有normal和small
    size: {
      type: String,
      default: () => {
        return 'normal';
      },
    },
    // 限制上传哪些格式的文件，默认为图片
    accept: {
      type: String,
      default: () => {
        return 'image/png,image/jpeg,image/jpg,image/bmp';
      },
    },
    // 上传文件数量限制,默认不限
    limit: {
      type: Number,
      default: () => {
        return Infinity;
      },
    },
    // 附件大小限制，默认不限
    maxSize: {
      type: Number,
      default: () => {
        return Infinity;
      },
    },
    isPdf: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      uploadDomain: 'https://upload.qiniup.com',
      qiniuData: {
        key: '', // 图片名字处理
        token: '', // 七牛云token
        data: {},
      },
      downloadDomain: '',
      fileList: _.cloneDeep(this.value),
      copyFileList: _.cloneDeep(this.value),
      currentFileType: '',
    };
  },
  computed: {
    className: function () {
      let classes = ['attach_list'];
      if (this.disabled) {
        classes.push('attach_list--readonly');
      }
      classes.push(`attach_list--${this.size}`);
      return classes;
    },
    acceptDesc () {
      let desc = [];
      this.accept.split(',').forEach(s => {
        let d = this.fileTypeMap.get(s);
        d && desc.push(d);
      });
      return desc.join(',');
    },
  },
  watch: {
    value: {
      deep: true,
      handler: function () {
        if (!_.isEqual(this.value, this.copyFileList)) {
          this.fileList = _.cloneDeep(this.value);
        }
      },
    },
    fileList: {
      deep: true,
      handler: function () {
        if (!_.isEqual(this.fileList, this.copyFileList)) {
          this.copyFileList = _.cloneDeep(this.fileList);
        }
      },
    },
  },
  methods: {
    handleLimitExceed () {
      this.$message.warning(`超过最大上传数量 ${this.limit}`);
    },
    handleImagePreview (file) {
      window.open(file.url, '_blank');
    },
    handleImageRemove (file) {
      this.$emit('remove', file);
      let index = this.copyFileList.findIndex(t => t.url === file.url || t.uid === file.uid);
      if (index >= 0) {
        this.copyFileList.splice(index, 1);
        this.fileList = _.cloneDeep(this.copyFileList);
        this.$emit('input', _.cloneDeep(this.copyFileList));
      }
    },
    handleBeforeUpload (file) {
      if (this.accept && this.accept !== '*') {
        let isMatchFileType = this.accept.indexOf(file.type) >= 0;
        if (!isMatchFileType) {
          let desc = [];
          this.accept.split(',').forEach(s => {
            let d = this.fileTypeMap.get(s);
            d && desc.push(d);
          });
          this.$message.warning(`${this.label}仅支持${desc.join(',')}格式`);
          return;
        }
      }

      if (file.size / 1024 / 1024 > this.maxSize) {
        this.$message.warning(`${this.label}大小不能超过${this.maxSize}MB`);
        return;
      }
      this.currentFileType = file.type;
      this.qiniuData.data = file;
      this.qiniuData.key = `${file.name}`;
    },
    getDefaultCover (file) {
      let fileType = '';
      if (file.raw && file.raw.type) {
        fileType = this.fileTypeMap.get(file.raw.type) || '';
      } else if (file.type === 'image') {
        fileType = 'JPG';
      } else {
        let spl = file.url.split('.');
        fileType = spl[spl.length - 1];
      }
      let url = '';
      switch (fileType.toUpperCase()) {
      case 'PNG':
      case 'JPEG':
      case 'JPG':
      case 'GIF':
      case 'BMP':
        url = file.url;
        break;
      case 'PDF':
        url = require('../../assets/imgs/pdf.svg');//eslint-disable-line
        break;
      default:
        url = require('../../assets/imgs/pdf.svg');//eslint-disable-line
        break;
      }
      return url;
    },
    uploadSuccess (response, file) {
      let attach = {
        url: `${this.downloadDomain}/${response.key}`,
        attachId: response.hash,
        attachName: response.key,
        uid: file.uid,
      };
      this.$emit('success', attach);
      this.copyFileList.push(_.cloneDeep(attach));
      this.$emit('input', _.cloneDeep(this.copyFileList));
    },
  },
  created () {
    let fileTypeMap = new Map();
    fileTypeMap.set('image/png', 'PNG');
    fileTypeMap.set('image/jpeg', 'JPEG');
    fileTypeMap.set('image/jpg', 'JPG');
    fileTypeMap.set('image/gif', 'GIF');
    fileTypeMap.set('image/bmp', 'BMP');
    fileTypeMap.set('application/pdf', 'PDF');
    fileTypeMap.set('application/msword', 'DOC');
    fileTypeMap.set('application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'DOCX');
    fileTypeMap.set('application/vnd.ms-exceld', 'XLS');
    fileTypeMap.set('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'XLSX');
    fileTypeMap.set('application/vnd.ms-powerpoint', 'PPT');
    fileTypeMap.set('application/vnd.openxmlformats-officedocument.presentationml.presentation', 'PPTX');
    this.fileTypeMap = fileTypeMap;
    if (!this.disabled) {
      AccountApi.getQiniuToken(this.isPdf).then(res => {
        this.qiniuData.token = res.body.token;
        this.downloadDomain = res.body.domain;
      });
    }
  },
};
</script>
<style lang="scss" scoped>
 .attach_list {
    margin-bottom: 16px;

    &.attach_list--readonly{
        /deep/ .el-upload--picture-card{
            display: none;
        }
    }

    &.attach_list--normal {
      /deep/ .el-upload-list__item {
        width: 100px;
        height:110px;
      }

      /deep/ .el-upload--picture-card{
        width: 100px;
        height:110px;
      }
    }

    &.attach_list--small {
        /deep/ .el-upload-list__item {
          width: 80px;
          height:80px;
        }

        /deep/ .el-upload--picture-card{
          width: 80px;
          height:80px;
      }
    }

     &.attach_list--mini {
        /deep/ .el-upload-list__item {
          width: 60px;
          height:60px;
        }

        /deep/ .el-upload--picture-card{
          width: 60px;
          height:60px;
      }

      /deep/ .el-upload-list--picture-card {

        .el-upload-list__item-actions:hover span {
          font-size: 16px;
        }

        .el-upload-list__item-actions span + span {
          margin-left: 0px;
        }
      }
    }

    /deep/ .el-upload-list__item {
        margin-right: 16px;

        &>div{
          height: 100%;
          width: 100%;
        }
        img {
          max-width: 100%;
          max-height: 100%;
        }
    }

    /deep/ .el-upload--picture-card{
        position: relative;

        .el-icon-plus {
          position: absolute;
          top:50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
    }
}
</style>
